import {
  AddLineFriendTrackingWithInsight,
  Company,
} from 'components/Dashboard/types'
import React from 'react'
import AddLineFriendTrackingDetailModal from './AddLineFriendTrackingDetailModal'
import { useAppDispatch } from 'components/Dashboard/hooks'
import { archiveAddLineFriendTracking } from 'components/Dashboard/stores/addLineFriendTrackingsWithInsight'

interface Props {
  addLineFriendTrackingsWithInsight: AddLineFriendTrackingWithInsight[]
  liffUrl: string
  company: Company
  archivedCallback: () => void
}

const notShowDetailModalParameters = [null, 'oc_popup', 'restock_notification']

const AddLineFriendTrackingTable = ({
  addLineFriendTrackingsWithInsight,
  liffUrl,
  company,
  archivedCallback,
}: Props) => {
  const dispatch = useAppDispatch()
  const handleClickArchiveButton = async (id: number) => {
    if (
      window.confirm(
        'この経路を非表示にしますか？非表示にしてもこの経路のurlやQRコードは引き続きご利用いただけます。'
      )
    ) {
      try {
        await dispatch(archiveAddLineFriendTracking(company, id))
        archivedCallback()
      } catch (e) {
        console.error(e)
        window.alert(
          '非表示にすることができませんでした。ページを再読み込みしていただくか、時間をあけてから再度お試しください。'
        )
      }
    }
  }

  return (
    <div className="uk-section-default uk-padding-small">
      <div
        className="uk-overflow-auto"
        id="add-line-friend-tracking-detail-modal"
      >
        <table className="uk-table uk-table-striped uk-table-middle">
          <thead>
            <tr>
              <th style={{ minWidth: '10px' }}></th>
              <th style={{ minWidth: '100px' }}>経路名</th>
              <th style={{ minWidth: '60px' }}>友だち追加数</th>
              <th style={{ minWidth: '60px' }}>CV数</th>
              <th style={{ minWidth: '60px' }}>購入金額</th>
              <th style={{ minWidth: '60px' }}>あいさつメッセージ</th>
              <th style={{ minWidth: '10px' }}></th>
            </tr>
          </thead>
          <tbody>
            {addLineFriendTrackingsWithInsight.map(
              addLineFriendTrackingWithInsight => (
                <tr key={addLineFriendTrackingWithInsight.id}>
                  <td className="uk-text-small">
                    {!notShowDetailModalParameters.includes(
                      addLineFriendTrackingWithInsight.parameter
                    ) && (
                      <AddLineFriendTrackingDetailModal
                        rootId="add-line-friend-tracking-detail-modal"
                        addLineFriendTrackingId={
                          addLineFriendTrackingWithInsight.id
                        }
                        liffUrl={liffUrl}
                        title={addLineFriendTrackingWithInsight.title}
                        parameter={addLineFriendTrackingWithInsight.parameter}
                        company={company}
                      >
                        <div
                          className="material-icons"
                          style={{ fontSize: 18 }}
                        >
                          ios_share
                        </div>
                      </AddLineFriendTrackingDetailModal>
                    )}
                  </td>
                  <td className="uk-text-small">
                    {addLineFriendTrackingWithInsight.title}
                  </td>
                  <td className="uk-text-small">
                    {addLineFriendTrackingWithInsight.friendCount || 0}
                  </td>
                  <td className="uk-text-small">
                    {addLineFriendTrackingWithInsight.cvCount || 0}
                  </td>
                  <td className="uk-text-small">
                    {addLineFriendTrackingWithInsight.cvSumPrice || 0}円
                  </td>
                  <td className="uk-text-small"></td>
                  <td className="uk-text-small">
                    {!addLineFriendTrackingWithInsight.preBuild && (
                      <>
                        <div
                          className="material-icons"
                          style={{ fontSize: 18, cursor: 'pointer' }}
                        >
                          more_horiz
                        </div>
                        <div
                          uk-dropdown="mode: click"
                          className="uk-padding-small"
                        >
                          <ul className="uk-nav uk-dropdown-nav uk-nav-divider">
                            <li>
                              <a
                                onClick={() =>
                                  handleClickArchiveButton(
                                    addLineFriendTrackingWithInsight.id
                                  )
                                }
                              >
                                非表示にする
                              </a>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AddLineFriendTrackingTable
