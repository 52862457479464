import _ from 'lodash'
import {
  PushMessageFormImageObject,
  PushMessageFormImageWithLinkObject,
  PushMessageFormObject,
  PushMessageFormTextObject,
  PushMessageObject,
} from 'types.ts/PushMessage'

// 以下`PushMessageReservationForm`と`PushMessageTriggerForm`の共通処理
// 2つの共通部分（メッセージフォーム部分）をまとめてコンポーネント化したい

export const includeOcDomainUri = (messages: PushMessageFormObject[]) =>
  messages.some(obj => {
    if (obj.type === 'text') {
      return obj.text && obj.text.match(/omiseconnect\.jp/)
    }
    if (obj.type === 'imageWithLink') {
      return obj.uri && obj.uri.match(/omiseconnect\.jp/)
    }
    return false
  })

// NOTE: `PushMessageObject` を `PushMessageFormObject` に変換
export const convertDBObjectToFormObject = (
  pushMessageObject: PushMessageObject
): PushMessageFormObject => {
  const identifier = `${pushMessageObject.type}-${pushMessageObject.id}`
  if (pushMessageObject.type === 'image') {
    if (pushMessageObject.uri) {
      const formObj: PushMessageFormImageWithLinkObject = {
        ...pushMessageObject,
        type: 'imageWithLink',
        identifier,
        file: null,
      }
      return formObj
    } else {
      const formObj: PushMessageFormImageObject = {
        ...pushMessageObject,
        identifier,
        file: null,
      }
      return formObj
    }
  } else {
    // type === 'text'
    const formObj: PushMessageFormTextObject = {
      ...pushMessageObject,
      identifier,
    }
    return formObj
  }
}

export const checkFormObjectIsValid = (obj: PushMessageFormObject): boolean => {
  if (obj.type === null) return false

  if (obj.type === 'text') {
    return obj.text !== ''
  }
  const isImageValid = Boolean(!_.isNil(obj.file) || obj.imageSrc)
  if (obj.type === 'image') {
    return isImageValid
  }
  if (obj.type === 'imageWithLink') {
    return Boolean(
      isImageValid && obj.uri && !(obj.altText && obj.altText.length > 100)
    )
  }
  return false
}
