import React, { useEffect, useState } from 'react'
import { Company } from '../../types'
import AddLineFriendTrackingTable from './addLineFriendTrackingTable'
import { useAppDispatch, useAppSelector } from 'components/Dashboard/hooks'
import { RootState } from 'components/Dashboard/stores'
import {
  fetchAddLineFriendTrackingsWithInsight,
  fetchArchivedAddLineFriendTrackingsCount,
} from 'components/Dashboard/stores/addLineFriendTrackingsWithInsight'
import ReactLoading from 'react-loading'
import { DatePicker } from '@mui/x-date-pickers'
import Pagination from '@mui/material/Pagination'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import AddLineFriendTrackingFormModal from './AddLineFriendTrackingFormModal'

interface Props {
  company: Company
  liffUrl: string
}

// 遡って取得できる最小の日付
const minDate = '2024-08-20'
dayjs.extend(minMax)

const countPerPage = 20

const App = ({ company, liffUrl }: Props) => {
  const dispatch = useAppDispatch()
  // startDateの初期値は7日前にする。7日前が2024/08/20より前だった場合は2024/08/20にする
  const [startDate, setStartDate] = useState(
    dayjs.max(dayjs().add(-7, 'day'), dayjs(minDate))
  )
  const [endDate, setEndDate] = useState(dayjs().add(-1, 'day'))
  const [page, setPage] = useState(1)
  const {
    addLineFriendTrackingsWithInsight,
    totalCount,
    archivedAddLineFriendTrackingsCount,
  } = useAppSelector(
    (state: RootState) => state.addLineFriendTrackingsWithInsight
  )

  useEffect(() => {
    dispatch(fetchArchivedAddLineFriendTrackingsCount(company))
  }, [])

  useEffect(() => {
    dispatch(
      fetchAddLineFriendTrackingsWithInsight(company, startDate, endDate, page)
    )
  }, [startDate, endDate])

  useEffect(() => {
    dispatch(
      fetchAddLineFriendTrackingsWithInsight(company, startDate, endDate, page)
    )
  }, [page])

  const createAddLineFriendTrackingCallback = () => {
    dispatch(
      fetchAddLineFriendTrackingsWithInsight(company, startDate, endDate, page)
    )
  }

  const archivedChangedCallback = () => {
    dispatch(
      fetchAddLineFriendTrackingsWithInsight(company, startDate, endDate, page)
    )
    dispatch(fetchArchivedAddLineFriendTrackingsCount(company))
  }

  return (
    <div id="app-root">
      <div className="uk-flex">
        <h2 className="uk-h4">友だち追加経路一覧</h2>
      </div>
      <div>
        <AddLineFriendTrackingFormModal
          rootId="app-root"
          company={company}
          createAddLineFriendTrackingCallback={
            createAddLineFriendTrackingCallback
          }
          existingParameters={
            addLineFriendTrackingsWithInsight?.map(d => d.parameter) || []
          }
        />
      </div>
      <div className="uk-flex uk-flex-right uk-margin-top">
        <div className="uk-flex uk-flex-middle">
          <DatePicker
            // 2024/08/20までしか遡れないようにする
            // end_dateよりも後の日付を選択できないようにする
            minDate={dayjs(minDate)}
            maxDate={dayjs.min(dayjs().add(-1, 'day'), endDate)}
            defaultValue={startDate}
            onChange={date => {
              setStartDate(dayjs(date))
            }}
          />
          <div className="uk-margin-small-left uk-margin-small-right">〜</div>
          <DatePicker
            // start_dateよりも前の日付を選択できないようにする
            minDate={dayjs.max(dayjs(minDate), startDate)}
            maxDate={dayjs().add(-1, 'day')}
            defaultValue={endDate}
            onChange={date => {
              setEndDate(dayjs(date))
            }}
          />
        </div>
      </div>
      {addLineFriendTrackingsWithInsight ? (
        <AddLineFriendTrackingTable
          addLineFriendTrackingsWithInsight={addLineFriendTrackingsWithInsight}
          liffUrl={liffUrl}
          company={company}
          archivedCallback={archivedChangedCallback}
        />
      ) : (
        <div className="uk-margin-left">
          <ReactLoading
            type={'bubbles'}
            color={'#00888dbf'}
            height={80}
            width={80}
          />
        </div>
      )}
      <Pagination
        className="uk-flex uk-flex-center"
        count={Math.ceil(totalCount / countPerPage)}
        page={page}
        onChange={(_, value) => {
          setPage(value)
        }}
      />
      {archivedAddLineFriendTrackingsCount > 0 && (
        <div className="uk-margin-top">
          <a
            href={`/companies/${company.slug}/add_line_friend_trackings/archived`}
            className="uk-flex uk-text-small uk-text-muted uk-flex-middle"
            style={{ textDecoration: 'none' }}
          >
            非表示にした友だち追加経路一覧へ
            <span className="material-icons">chevron_right</span>
          </a>
        </div>
      )}
    </div>
  )
}

export default App
